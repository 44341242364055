

<template>
    <div>
        <modal height="auto" :name="modal_name" class="final-modal" transition="nice-modal-fade">
            <div class="v-modal-content p-0">
                <div class="v-modal-header v-modal-header-new-back" style="background-color:#00448b !important">
                    <span class="v-modal-dialog-title v-modal-title fw-600 py-3 ml-auto mr-auto text-uppercase" style="color: #fff !important;letter-spacing:1px;">ADD Access</span>
                    <button  type="button" class="pointer position-absolute" style="background-color: #00448b !important;outline:none; right: 0; top: 10px;" aria-label="Close" @click="hideAddAccess()">
                        <span aria-hidden="true">
                            <i class="icon icon-close" style="color:white;margin:10px 10px 0 0;"></i>
                        </span>
                    </button>
                </div>
                <div class="m-4">
                    <div class="v-modal-body pt-0 pb-0" >
                        <div class="mb-10 mx-auto" style="max-width: 350px;">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 mt-5">
                                    <div class="w-100">
                                        <span class="input-label">Employee Name</span>
                                        <div class="d-flex align-items-center" style="margin-top: .7rem !important;">
                                            <multiselect class="diginew-multiselect" name="employee_name" label="full_name" @search-change="getEmployeesOptions" :show-labels="false" :loading="loading" :searchable="true" :options="employee_options" :max-height="200" v-model="employee_name" placeholder="Select Employee">
                                                <template slot="noOptions">
                                                    <div class="text-secondary text-center fw-600">List is Empty</div>
                                                </template>
                                                <template slot="noResult">
                                                    <div class="text-secondary text-center fw-600">No Results Found</div>
                                                </template>
                                                <template slot="afterList">
                                                    <div v-observe-visibility="reachedEndOfListDropdown" />
                                                </template>
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 mt-5">
                                    <div class="w-100">
                                        <span class="input-label">Permission Roles</span>
                                        <div class="d-flex align-items-center" style="margin-top: .7rem !important;">
                                            <multiselect class="diginew-multiselect" name="employee_name" label="name" @search-change="getPermssionRoleOptions" :show-labels="false" :loading="loading" :searchable="true" :max-height="200" :options="permission_role_options" v-model="permission_role" placeholder="Select Role">
                                                <template slot="noOptions">
                                                    <div class="text-secondary text-center fw-600">List is Empty</div>
                                                </template>
                                                <template slot="noResult">
                                                    <div class="text-secondary text-center fw-600">No Results Found</div>
                                                </template>
                                                <template slot="afterList">
                                                    <div v-observe-visibility="reachedEndOfListPermissionRoles" />
                                                </template>
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h1 v-if="err_text" class="text-danger fs-14 mt-2">{{err_text}}</h1>
                            <h1 v-if="success_text" class="text-success fs-14 mt-2">{{success_text}}</h1>
                            
                        </div>
                    </div>
                    <div class="v-modal-dialog-actions text-center" style="border-radius: 0px 0px 15px 15px;padding:15px;">
                        <a class="btn btn-outline-secondary" style="width: 120px;border-radius: 5px;" @click="hideAddAccess()">Cancel</a>
                        <a class="btn btn-new-success text-white ml-4" style="width: 120px;border-radius: 5px;" @click="save()">Save</a>
                    </div> 
                </div>     
            </div>
        </modal>
    </div>
</template>

<script>
import axios from 'axios';
import companies from '../mixins/companies';
import product from '../mixins/product';
import globals from '../globals';

export default {
    props:['modal_name'],
    mixins: [companies, product],
    data(){
        return{
            employee_options: [],
            employee_name: '',
            loading: false,
            permission_role_options: [],
            permission_role: '',
            temp_dropdown_options: [],
            skip: 0,
            limit: 10,
            count: 0,
            roles_count: 0,
            isVisible: false,
            scrollable: false,
            search_key: '',
            err_text: '',
            success_text: ''
        }
    },
    methods: {
        hideAddAccess(){
            this.$emit('hideAddAccess');
        },
        async getEmployeesOptions(key) {
            this.search_key = key;
            this.loading = true;
            let params = {
                skip: this.skip,
                limit: 10,
                search_key: this.search_key,
                company_id: this.$route.params.company_id,
                product_id: this.$route.params.id,
                is_employee_access: true
            }
            await this.$http.get(globals.AUTH_SERVICE + `/employees/?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}&company_id=${params.company_id}&product_id=${params.product_id}&is_employee_access=${params.is_employee_access}`).then(response => {
                if(response.data.status_id == 1) {
                    this.employee_options = response.data.response
                    this.count = response.data.response.length;
                }
                this.loading = false;
            }).then((err) => {
                this.loading = false;
            });
        },
        async reachedEndOfListDropdown(reached) {
            this.isVisible = reached;
            if (reached) {
                if (this.scrollable == false) {
                    this.loading = true;
                    let params = {
                        skip: this.count,
                        limit: 10,
                        search_key: this.search_key,
                        company_id: this.$route.params.company_id,
                        product_id: this.$route.params.id,
                        is_employee_access: true
                    };
                    await this.$http.get(globals.AUTH_SERVICE + `/employees/?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}&company_id=${params.company_id}&product_id=${params.product_id}&is_employee_access=${params.is_employee_access}`)
                    .then((response) => {
                        this.temp_dropdown_options = response.data.response.length;;
                        if (response.data.skip == this.count) {
                            if (this.temp_dropdown_options > 0) {
                                let tempFiles = response.data.response;
                                tempFiles.forEach(el => {
                                    this.employee_options.push(el);
                                });
                                this.count += response.data.response.length;
                                this.loading = false
                            } else {
                                this.scrollable = true
                                this.loading = false
                            }
                        }
                        this.loading = false
                    });
                }
            }
        },
        async getPermssionRoleOptions(key) {
            this.search_key = key;
            this.loading = true;
            try {
                let params = {
                    skip: this.skip,
                    limit: 10,
                    search_key: this.search_key,
                    product_id: this.$route.params.id
                }
                let response = await this.getListProductRole(params)
                if(response.status_id == 1) {
                    this.permission_role_options = response.response
                    this.roles_count = response.response.length;
                }
                this.loading = false;
            }
            catch(err) {
                this.loading = false;
            }
        },
        async reachedEndOfListPermissionRoles(reached) {
            this.isVisible = reached;
            if (reached) {
                if (this.scrollable == false) {
                    this.loading = true;
                    let params = {
                        skip: this.roles_count,
                        limit: 10,
                        search_key: this.search_key,
                        product_id: this.$route.params.id
                    };
                    let response = await this.getListProductRole(params)
                    this.temp_dropdown_options = response.response.length;;
                    if (response.skip == this.roles_count) {
                        if (this.temp_dropdown_options > 0) {
                            let tempFiles = response.response;
                            tempFiles.forEach(el => {
                                this.permission_role_options.push(el);
                            });
                            this.roles_count += response.response.length;
                            this.loading = false
                        } else {
                            this.scrollable = true
                            this.loading = false
                        }
                    }
                    this.loading = false
                }
            }
        },
        async save() {
            let params = {
                employee_id: this.employee_name.id,
                role_id: this.permission_role.id,
                product_id: this.$route.params.id
            };
            await axios.post(globals.AUTH_SERVICE + `/employees/add/product`, params).then((response) => {
                if(response.data.status_id == 1) {
                    this.success_text = response.data.message;
                    setTimeout(() => {
                        this.$emit('saveAddSuccess');
                    }, 1000);
                }
            }).catch((err) => {
                this.err_text = err.response.data.reason;
            });
        }

    }
}

</script>

<style scoped>

.v-modal-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}
.v-modal-content{
    padding: 5px;
}
.v-modal-body div form{
    display: flex;
    flex-direction: column;
}

.v-modal-body div input{
    margin-bottom: 10px;
    padding: 10px;
    outline: none;
    border-radius: 5px;
    border: 1px solid black;
    opacity: 0.71
}
.btn-outline-secondary {
    color: #e82828 !important;
    border: 1px solid #e82828 !important;
}
.btn-outline-secondary:hover {
    color: #fff !important;
    background-color: #e82828;
    border-color: #e82828 !important;
}
</style>
